import Script from 'next/script';
import type { AppProps } from 'next/app';
import { Poppins } from 'next/font/google';

const brandFont = Poppins({
  subsets: ['latin'],
  weight: ['400', '700', '900'],
  variable: '--font-brand',
});

import '@/styles/globals.scss';
import '@/styles/globals.css';
import classNames from 'classnames';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <div className={classNames(brandFont.variable)}>
      <Component {...pageProps} />
      {process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true' ? (
        <>
          <Script defer data-domain="imawakatta.com" src="https://plausible.io/js/plausible.js" />
          <Script id="plausible">
            {`
              window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }
            `}
          </Script>
        </>
      ) : (
        <Script id="plausible">
          {`
            window.plausible = (...args) => console.log(...args)
          `}
        </Script>
      )}
    </div>
  );
}
